import { css } from 'styled-components'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

export const FormCSS = css`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 2rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: #e9f0f7;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    background-color: #e9f0f7;
    padding: 15px 20px;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: green;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .form-duuf-group-consent {
    font-style: italic;

    & input {
      margin-right: 0.5rem;
      appearance: none;
      width: 16px;
      height: 16px;
      background-color: #e9f0f7;

      &:checked {
        background-color: ${({ theme }) => theme.color.secondary};
      }
    }

    & a {
      color: ${({ theme }) => theme.color.secondary};

      &:hover {
        text-decoration: underline;
      }
    }
  }
`
