import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Mousewheel, Navigation } from 'swiper'
import Plaatjie from '@ubo/plaatjie'

// Third party CSS
import 'swiper/css'
import 'swiper/css/pagination'

// Elements
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'

// Images
import SliderArrow from 'img/slider-arrow.inline.svg'

// Interface
import { SliderProps } from 'components/flex/Slider/SliderShell'

const Section = styled.section`
  z-index: 4;
`

const Content = styled(ParseContent)`
  opacity: 0.3;
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${({ theme }) => theme.color.primary} !important;
  }

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 70px;
      line-height: 70px;
    }
  }

  @media (max-width: 991.98px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.mega};
      line-height: ${({ theme }) => theme.font.size.mega};
    }
  }
`

const SwiperContainer = styled.div`
  max-width: calc(((100% - 1100px) / 2) + 1100px) !important;
  & .swiper-slide {
    height: auto;
    border-bottom-right-radius: 50px;
    &:last-child {
      margin-right: 5rem !important;
    }
  }
`

const SliderNav = styled.div`
  & > div:hover {
    cursor: pointer;
    & svg > circle {
      fill: ${({ theme }) => theme.color.primary};
    }
  }

  & .custom-swiper-button-next {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    height: 40px;
    z-index: 10;
    display: block;

    circle {
      fill: ${({ theme }) => theme.color.secondary};
    }
  }

  & .custom-swiper-button-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 60px;
    z-index: 10;
    margin-top: auto;
    margin-bottom: auto;
    transform: rotate(180deg);
    height: 40px;

    circle {
      fill: ${({ theme }) => theme.color.secondary};
    }
  }

  & .swiper-button-disabled {
    opacity: 0.7;
    cursor: default !important;
  }
`

const InnerSlide = styled.div`
  bottom: 0;
  color: ${({ theme }) => theme.color.light};
  background: linear-gradient(0deg, #004b96 0%, rgba(0, 75, 150, 0) 100%);
`

const TitleSlide = styled(ParseContent)`
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);

  & h3 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    letter-spacing: 0.5px;
  }

  @media (min-width: 576px) {
    & h3 {
      font-size: ${({ theme }) => theme.font.size.large};
    }
  }

  @media (max-width: 575.98px) {
    & h3 {
      font-size: ${({ theme }) => theme.font.size.medium};
      line-height: 18px;
    }
  }
`

const ContentSlide = styled(ParseContent)`
  & * {
    font-size: ${({ theme }) => theme.font.size.small};
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 575.98px) {
    & * {
      font-size: ${({ theme }) => theme.font.size.tiny};
    }
  }
`

const SliderDefault: React.FC<SliderProps> = ({ fields }) => {
  const [currentHover, setCurrentHover] = useState(0)
  const [slideEnd, setSlideEnd] = useState(false)

  return (
    <Section className="position-relative mb-5 pb-lg-5" id={fields.sectionid}>
      <div className="container">
        <Content content={fields.description || ''} />
      </div>
      <SwiperContainer
        className={`position-relative container d-flex align-items-end ps-xl-0 pe-0 ${
          slideEnd ? 'me-5' : 'me-0'
        }`}
      >
        <SliderNav className="d-none d-sm-flex align-items-center">
          <div className="custom-swiper-button-prev">
            <SliderArrow />
          </div>
          <div className="custom-swiper-button-next ms-3">
            <SliderArrow />
          </div>
        </SliderNav>
        <Swiper
          className="ms-3 ms-sm-5"
          modules={[Navigation, Mousewheel]}
          mousewheel={{
            forceToAxis: true,
          }}
          navigation={{
            prevEl: '.custom-swiper-button-prev',
            nextEl: '.custom-swiper-button-next',
          }}
          breakpoints={{
            0: {
              slidesPerView: 2.4,
              spaceBetween: 10,
            },
            576: {
              slidesPerView: 1.66,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 2.25,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 3.33,
              spaceBetween: 30,
            },
          }}
          onSlideChange={(slide) => setSlideEnd(slide.isEnd)}
        >
          {fields.slider?.map((slide, index) => (
            <SwiperSlide
              key={index}
              className="position-relative overflow-hidden"
            >
              <NavLink
                target={slide?.link?.target}
                to={slide?.link?.url || '#'}
              >
                <motion.div
                  className="h-100"
                  onHoverStart={() => setCurrentHover(index)}
                >
                  <motion.div
                    animate={
                      currentHover === index ? { scale: 1.05 } : { scale: 1 }
                    }
                    className="h-100"
                  >
                    <Plaatjie
                      loading="eager"
                      image={slide?.image}
                      alt=""
                      className="h-100"
                    />
                  </motion.div>
                  <InnerSlide className="position-absolute w-100">
                    <div className="p-3 p-sm-4">
                      <TitleSlide
                        content={`<h3>${slide?.link?.title}</h3>`}
                        className="mb-2"
                      />
                      {currentHover === index && (
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{ height: 'fit-content' }}
                          className="d-none d-lg-block"
                        >
                          <ContentSlide
                            content={slide?.description || ''}
                            className="pe-sm-4"
                          />
                        </motion.div>
                      )}
                    </div>
                  </InnerSlide>
                </motion.div>
              </NavLink>
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperContainer>
      <div className="container">
        <SliderNav className="d-flex d-sm-none align-items-center justify-content-end px-3 mt-4">
          <div className="custom-swiper-button-prev">
            <SliderArrow />
          </div>
          <div className="custom-swiper-button-next ms-3">
            <SliderArrow />
          </div>
        </SliderNav>
      </div>
    </Section>
  )
}

export default SliderDefault
