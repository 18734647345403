import React from 'react'
import { PageProps } from 'gatsby'

// Components
import SliderDefault from 'components/flex/Slider/SliderDefault'
import SliderBasic from 'components/flex/Slider/SliderBasic'

export interface SliderProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Slider
  location: PageProps['location']
}

interface PostProps {
  [key: string]: any
}

const SliderShell: React.FC<SliderProps> = ({ fields, location }) => {
  const types: PostProps = {
    default: SliderDefault,
    basic: SliderBasic,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default SliderShell
