import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

import Chevron from 'img/chevron-button.inline.svg'

export const ButtonSecondaryCSS = css`
  position: relative;
  background-color: transparent;
  display: inline-block;
  border: 2px solid ${({ theme }) => theme.color.light};
  border-radius: 50px;
  min-width: 120px;
  overflow: hidden;

  & > a,
  & > button {
    position: relative;
    color: ${(props) => props.theme.color.light} !important;
    font-weight: ${({ theme }) => theme.font.weight.light};
    padding: 10px 20px;
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none !important;
    text-align: center;
    z-index: 2;
  }

  &:hover {
    & .wave {
      top: 0;
    }
  }

  & .wave {
    top: calc(100% + 22px);
    left: 0;
    background-color: #399974;
    transition: all 1s;
    z-index: -1;

    &:before {
      position: absolute;
      left: 0;
      content: '';
      width: 100%;
      height: 22px;
      background-image: url('/wave.png');
      top: -22px;
      animation: wavy 0.8s linear infinite;
    }
  }

  @keyframes wavy {
    0% {
      background-position-x: 0;
    }
    100% {
      background-position-x: 122px;
    }
  }
`

const StyledButton = styled(motion.span)`
  ${ButtonSecondaryCSS};
`

interface ButtonSecondaryProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  className?: string
  target?: string
  chevron?: boolean
}

const ButtonSecondary: React.FC<ButtonSecondaryProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  chevron,
  className = '',
  target = '',
}) => {
  const [hovered, setHovered] = useState(false)

  return (
    <StyledButton
      className={className}
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
    >
      {isCustom ? (
        <>
          {children}
          <div className="wave position-absolute w-100 h-100" />
          {chevron && (
            <motion.span
              animate={hovered ? { x: 30 } : { x: 20 }}
              className="d-inline-block"
            >
              <Chevron className="me-4" />
            </motion.span>
          )}
        </>
      ) : (
        <ButtonShell to={to} isAnchor={isAnchor} target={target}>
          {children}
          <div className="wave position-absolute w-100 h-100" />
          {chevron && (
            <motion.span
              animate={hovered ? { x: 30 } : { x: 20 }}
              className="d-inline-block"
            >
              <Chevron className="me-4" />
            </motion.span>
          )}
        </ButtonShell>
      )}
    </StyledButton>
  )
}

export default ButtonSecondary
