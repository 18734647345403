import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

// Images
import Quote from 'img/quote.inline.svg'

// Interface
import { CtaProps } from 'components/flex/Cta/CtaShell'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${({ theme }) => theme.color.primary};
  }

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      position: relative;
      font-size: ${({ theme }) => theme.font.size.mega};
      line-height: 58px;

      &:before {
        position: absolute;
        content: '';
        width: 2000px;
        height: 2px;
        top: 26px;
        right: 110%;
        background-color: ${({ theme }) => theme.color.primary};
      }
    }
  }
`

const QuoteWrapper = styled.div`
  background-color: #e2ebf4;
  border-bottom-right-radius: 50px;

  @media (min-width: 1400px) {
    padding: 4rem 5rem 5rem 11rem;
  }

  @media (max-width: 1399.98px) {
    padding: 1rem 3rem 3rem 10rem;
  }

  @media (max-width: 1199.98px) {
    padding: 1rem 1rem 1rem 9rem;
  }

  @media (max-width: 991.98px) {
    padding: 1rem 2rem 2rem 7rem;
  }

  @media (max-width: 767.98px) {
    padding: 1rem 1rem 1rem 5rem;
  }
`

const SVGWrapper = styled.div`
  margin-left: -2rem;

  @media (max-width: 575.98px) {
    & svg {
      width: 50px;
      height: 50px;
    }
  }
`

const QuoteContent = styled(ParseContent)`
  color: ${({ theme }) => theme.color.primary};

  @media (min-width: 992px) {
    & blockquote {
      font-size: ${({ theme }) => theme.font.size.bigger};
      margin-bottom: 2rem;
    }
  }
`

const Image = styled(Plaatjie)`
  bottom: 0;
  height: 100%;
  width: 100%;

  @media (min-width: 1400px) {
    height: 130%;
    width: 45%;
    max-height: 490px;
    max-width: 400px;
  }

  @media (min-width: 576px) {
    left: -95px;
  }

  @media (max-width: 1399.98px) {
    max-height: 350px;
    max-width: 260px;
  }

  @media (max-width: 1199.98px) {
    max-height: 320px;
    max-width: 230px;
  }

  @media (max-width: 991.98px) {
    max-height: 320px;
    max-width: 210px;
  }

  @media (max-width: 767.98px) {
    max-height: 290px;
    max-width: 200px;
  }

  @media (max-width: 575.98px) {
    max-height: 190px;
    max-width: 100px;
    left: -20px;
  }
`

const Title = styled.h2`
  opacity: 0.3;
  color: ${({ theme }) => theme.color.primary};
  @media (min-width: 992px) {
    font-size: 70px;
    line-height: 70px;
  }

  @media (max-width: 991.98px) {
    font-size: ${({ theme }) => theme.font.size.huge};
    line-height: ${({ theme }) => theme.font.size.huge};
  }
`

const CTA = styled.div``

const ContentWrapper = styled.div`
  z-index: 5;
`

const Section = styled.div`
  z-index: 4;
`

const CtaQuote: React.FC<CtaProps> = ({ fields }) => (
  <Section className="position-relative">
    <div className="container">
      <div className="row justify-content-end">
        <div className="col-lg-11">
          <div className="row justify-content-between">
            <ContentWrapper className="position-relative col-4">
              <Content
                content={fields.description || ''}
                className="position-relative mt-sm-5 pt-sm-5 mt-lg-0"
              />
              <CTA className="mt-4 mt-sm-5 pt-sm-5">
                <ButtonPrimary
                  to={fields.link?.url || '#'}
                  minWidth={200}
                  fontWeight="semi"
                  target={fields.link?.target}
                >
                  {fields.link?.title}
                </ButtonPrimary>
              </CTA>
            </ContentWrapper>
            <div
              className="position-relative col-8 col-lg-6"
              style={{ zIndex: 2 }}
            >
              <div className="d-flex justify-content-end">
                <Title className="mb-0 font-weight-bold">{fields.title}</Title>
              </div>
              <div className="position-relative">
                <Image
                  loading="eager"
                  image={fields.image}
                  alt=""
                  className="position-absolute"
                />
                <QuoteWrapper>
                  <SVGWrapper className="mb-sm-5">
                    <Quote />
                  </SVGWrapper>
                  <QuoteContent content={fields.quote || ''} />
                </QuoteWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
)

export default CtaQuote
