import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

// Images
import Beam from 'img/bannerbeam.inline.svg'

// Interface
import { CtaProps } from 'components/flex/Cta/CtaShell'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${({ theme }) => theme.color.primary};
  }

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      position: relative;
      font-size: ${({ theme }) => theme.font.size.mega};
      line-height: 58px;

      &:before {
        position: absolute;
        content: '';
        width: 2000px;
        height: 2px;
        top: 26px;
        right: 110%;
        background-color: ${({ theme }) => theme.color.primary};
      }
    }
  }
`

const QuoteWrapper = styled.div`
  background-color: #e2ebf4;
  border-bottom-right-radius: 50px;

  @media (min-width: 1400px) {
    padding: 8rem 5rem 7rem 8rem;
  }

  @media (max-width: 1399.98px) {
    padding: 1rem 1rem 1rem 10rem;
  }

  @media (max-width: 1199.98px) {
    padding: 1rem 1rem 1rem 9rem;
  }

  @media (max-width: 991.98px) {
    padding: 1rem 1rem 1rem 7rem;
  }

  @media (max-width: 767.98px) {
    padding: 1rem 1rem 1rem 5rem;
  }

  @media (max-width: 575.98px) {
    padding: 1rem;
  }
`

const QuoteContent = styled(ParseContent)`
  @media (min-width: 992px) {
    & p {
      font-size: ${({ theme }) => theme.font.size.bigger};
      line-height: ${({ theme }) => theme.font.size.huge};
      margin-bottom: 2rem;
    }
  }
`

const Image = styled(Plaatjie)`
  height: 100%;
  width: 100%;

  @media (min-width: 1400px) {
    max-height: 400px;
    max-width: 430px;
    left: -305px;
    top: -70px;
  }

  @media (max-width: 1399.98px) {
    max-height: 295px;
    max-width: 295px;
    left: -200px;
    top: -20px;
  }

  @media (max-width: 1199.98px) {
    max-height: 320px;
    max-width: 320px;
    left: -200px;
  }

  @media (max-width: 991.98px) {
    max-height: 180px;
    max-width: 180px;
    top: 0;
    left: -100px;
  }

  @media (max-width: 767.98px) {
    max-height: 130px;
    max-width: 130px;
    top: 70px;
    left: -60px;
  }

  @media (max-width: 575.98px) {
    top: 125px;
    left: -120px;

    max-height: 110px;
    max-width: 110px;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.color.primary};
  opacity: 0.3;
  @media (min-width: 992px) {
    margin-right: -4rem;
    font-size: 70px;
    line-height: 70px;
  }

  @media (max-width: 991.98px) {
    font-size: ${({ theme }) => theme.font.size.huge};
    line-height: ${({ theme }) => theme.font.size.huge};
  }
`

const CTA = styled.div`
  @media (max-width: 575.98px) {
    & > span {
      z-index: 4;
    }
    & a {
      font-size: ${({ theme }) => theme.font.size.tiny} !important;

      & svg {
        display: none;
      }
    }
  }
`

const Section = styled.div`
  z-index: 4;
`

const SVGWrapper = styled.div`
  bottom: 100px;
  left: 0;
`

const CtaDownload: React.FC<CtaProps> = ({ fields }) => (
  <Section className="position-relative">
    <SVGWrapper className="position-absolute ">
      <Beam />
    </SVGWrapper>
    <div className="container">
      <div className="row justify-content-end">
        <div className="col-lg-11">
          <div className="row justify-content-between">
            <div className="col-4">
              <Content
                content={fields.description || ''}
                className="mt-sm-5 pt-sm-5"
              />
              <CTA className="mt-4 mt-sm-5 pt-sm-5">
                <ButtonPrimary
                  to={fields.download?.localFile?.publicURL || '#'}
                  chevron
                  target="_blank"
                >
                  Download
                </ButtonPrimary>
              </CTA>
            </div>
            <div
              className="position-relative col-8 col-lg-6"
              style={{ zIndex: 2 }}
            >
              <div className="d-flex justify-content-end">
                <Title className="mb-0 font-weight-bold">{fields.title}</Title>
              </div>
              <div className="position-relative">
                <Image
                  image={fields.image}
                  alt=""
                  className="position-absolute"
                />
                <QuoteWrapper>
                  <QuoteContent content={fields.quote || ''} />
                </QuoteWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
)

export default CtaDownload
