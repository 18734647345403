import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import Plaatjie from '@ubo/plaatjie'

// Third party CSS
import 'swiper/css'
import 'swiper/css/pagination'

// Elements
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'

// Interface
import { SliderProps } from './SliderShell'

const SwiperContainer = styled.div`
  @media (max-width: 575.98px) {
    padding-right: 0 !important;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${({ theme }) => theme.color.primary};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
  }

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.ultra};
    }
  }
`

const Image = styled(Plaatjie)``

const LinkSlide = styled(NavLink)`
  &:hover {
    opacity: 0.75;
  }
`

const SliderBasic: React.FC<SliderProps> = ({ fields }) => (
  <section className="mb-5 py-lg-5">
    <SwiperContainer className="container">
      <Content content={fields.description || ''} className="mb-4 pb-lg-5" />
      <Swiper
        className="py-4"
        breakpoints={{
          0: {
            slidesPerView: 2.33,
            spaceBetween: 25,
          },
          576: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 35,
          },
          992: {
            slidesPerView: 5,
            spaceBetween: 40,
          },
          1200: {
            slidesPerView: 6,
            spaceBetween: 45,
          },
        }}
      >
        {fields.slider?.map((slide, index) => (
          <SwiperSlide
            key={index}
            className="position-relative overflow-hidden"
          >
            <LinkSlide
              target={slide?.link?.target}
              to={slide?.link?.url || '#'}
            >
              <Image loading="eager" image={slide?.image} alt="" />
            </LinkSlide>
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperContainer>
  </section>
)

export default SliderBasic
