import React from 'react'
import styled from 'styled-components'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const BreadcrumbContainer = styled.div`
  position: relative;
  z-index: 4;

  & nav {
    margin-bottom: 0;
  }

  & ol {
    padding: 0;
    margin: 0;
    display: flex;
    font-family: ${({ theme }) => theme.font.family.secondary} !important;
  }

  & li {
    list-style: none;

    &:last-child a {
      color: ${({ theme }) => theme.color.secondary};
    }
  }

  & a {
    font-size: 16px;
    line-height: 35px;
    opacity: 0.8;
    color: ${({ theme }) => theme.color.light};
    display: inline-block;

    &[aria-current='page'] {
      opacity: 1;
    }

    &:first-letter {
      text-transform: uppercase;
    }

    &:hover {
      opacity: 1;
      color: ${({ theme }) => theme.color.secondary};
      text-decoration: underline;
    }
  }

  .breadcrumb__separator {
    margin: 3px 8px 4px 8px;
    font-size: 20px;
    color: ${({ theme }) => theme.color.light};
  }

  .breadcrumb__list {
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: 575.98px) {
    & a {
      font-size: 14px;
    }
  }
`

interface CrumbsProps {
  pageContext?: any
  label?: string
  className?: string
}

const Breadcrumbs: React.FC<CrumbsProps> = ({
  pageContext,
  label,
  className = '',
}) => {
  let crumbs = []

  if (pageContext) {
    crumbs = pageContext.breadcrumb.crumbs
  } else {
    crumbs = [
      {
        crumbLabel: 'preview',
        pathname: '#',
      },
      {
        crumbLabel: 'breadcrumbs',
        pathname: '#',
      },
    ]
  }

  crumbs.forEach((element: any) => {
    // eslint-disable-next-line no-param-reassign
    element.crumbLabel = element.crumbLabel.replace(/-/g, ' ')
  })

  return (
    <BreadcrumbContainer>
      <Breadcrumb
        crumbs={crumbs}
        crumbSeparator=" > "
        crumbLabel={label}
        className={className}
      />
    </BreadcrumbContainer>
  )
}

export default Breadcrumbs
