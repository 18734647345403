import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import FormContact from '../Form/FormContact'
import NavLink from 'components/shared/NavLink'

// Images
import Email from 'img/email.inline.svg'
import Phone from 'img/phone.inline.svg'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'
import Breadcrumbs from 'components/elements/Misc/Breadcrumbs'

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.primary};
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    position: relative;
    line-height: ${({ theme }) => theme.font.size.giga};

    &:before {
      position: absolute;
      content: '';
      width: 2000px;
      top: 30px;
      right: 103%;
      height: 2px;
      background-color: ${({ theme }) => theme.color.light};
    }
  }

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.mega};
    }
  }
`

const InfoWrapper = styled.div`
  z-index: 4;
`

const InfoContent = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 2rem;
  }

  & p {
    line-height: ${({ theme }) => theme.font.size.larger};
  }
`

const ContentWrapper = styled.div`
  padding-top: 14rem;
  padding-bottom: 5rem;
  background: linear-gradient(
    180deg,
    #012345 35.36%,
    rgba(1, 35, 69, 0) 92.19%
  );
`

const BannerWrapper = styled.div`
  @media (min-width: 1400px) {
    min-height: 907px;
  }
`

const Image = styled(Plaatjie)`
  @media (max-width: 767.98px) {
    max-height: 700px;

    & img {
      object-fit: contain !important;
    }
  }
`

const FormWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  border-bottom-right-radius: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (min-width: 992px) {
    margin-bottom: 12rem;
  }

  @media (max-width: 991.98px) {
    margin-bottom: 3rem;
  }
`

const AddressWrapper = styled.div`
  color: ${({ theme }) => theme.color.light};
`

const Links = styled.div`
  & a {
    color: ${({ theme }) => theme.color.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};

    &:hover {
      color: ${({ theme }) => theme.color.secondary};
      text-decoration: underline;
    }
  }

  & svg {
    margin-right: 15px;
  }
`

const Container = styled.div`
  z-index: 4;
`

const BannerContact: React.FC<BannerProps> = ({
  fields,
  location,
  pageContext,
}) => (
  <Section className="position-relative">
    <BannerWrapper className="position-relative">
      <Image
        image={fields.image}
        alt=""
        className="position-absolute w-100 h-100"
      />
      <ContentWrapper className="position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <div className="row">
                <div className="col-sm-6">
                  <Content
                    content={fields.description || ''}
                    className="position-relative"
                  />
                  <div className="mt-5">
                    <Breadcrumbs pageContext={pageContext} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentWrapper>
      <InfoWrapper className="position-relative">
        <Container className="position-relative container">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <div className="row">
                <div className="col-lg-6 order-lg-2">
                  <FormWrapper className="p-4 p-lg-5">
                    <FormContact fields={fields} location={location} />
                  </FormWrapper>
                </div>
                <div className="col-lg-6 order-lg-1">
                  <AddressWrapper className="position-relative mt-lg-5 pt-lg-5 pb-5 pb-lg-0">
                    <InfoContent content={fields.info?.description || ''} />
                    <Links className="mt-4">
                      <div className="d-flex align-items-center">
                        <Phone />
                        <NavLink to={fields.info?.phone?.url || '#'}>
                          {fields.info?.phone?.title}
                        </NavLink>
                      </div>
                      <div className="d-flex align-items-center">
                        <Email />
                        <NavLink to={fields.info?.email?.url || '#'}>
                          {fields.info?.email?.title}
                        </NavLink>
                      </div>
                    </Links>
                  </AddressWrapper>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </InfoWrapper>
    </BannerWrapper>
  </Section>
)

export default BannerContact
