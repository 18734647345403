import React from 'react'
import styled from 'styled-components'

// CSS
import { FormCSS } from 'styles/form'

import { useFormDuuf } from '@ubo/form-duuf'

// Components
import FormDuuf from 'components/related/Form'
import { DownloadsProps } from 'components/flex/Demo/DemoDownloads'
import ParseContent from 'components/shared/ParseContent'

const StyledFormDuuf = styled(FormDuuf)`
  ${FormCSS}
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 2rem;
  }

  & p {
    color: ${({ theme }) => theme.color.dark};
  }
`

const FormDownload: React.FC<DownloadsProps> = ({ fields, download }) => (
  <>
    <Content content={fields.description || ''} className="mb-4" />
    <StyledFormDuuf
      id={2}
      generate
      privacyUrl="/privacy-policy/"
      privacyUrlPlacement="privacy policy"
      defaultFields={{ input_1: download }}
    />
  </>
)

export default FormDownload
