import React from 'react'
import { PageProps } from 'gatsby'

// Components
import TextDefault from 'components/flex/Text/TextDefault'
import TextColumns from 'components/flex/Text/TextColumns'
import TextLinks from 'components/flex/Text/TextLinks'
import TextImage from 'components/flex/Text/TextImage'

export interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location?: PageProps['location']
}

interface PostProps {
  [key: string]: any
}

const TextShell: React.FC<TextProps> = ({ fields, location }) => {
  const types: PostProps = {
    default: TextDefault,
    columns: TextColumns,
    links: TextLinks,
    image: TextImage,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default TextShell
