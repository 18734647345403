import React from 'react'
import { PageProps } from 'gatsby'

// Components
import Blog from 'components/flex/Posts/Blog'
import Highlighted from 'components/flex/Posts/Highlighted'

export interface PostsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location?: PageProps['location']
}

interface PostProps {
  [key: string]: any
}

const PostsShell: React.FC<PostsProps> = ({ fields, location }) => {
  const types: PostProps = {
    blog: Blog,
    highlighted: Highlighted,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default PostsShell
