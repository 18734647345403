import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import Member from 'components/elements/Misc/Member'
import ParseContent from 'components/shared/ParseContent'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'

// Third party CSS
import 'swiper/css'
import 'swiper/css/pagination'

// Images
import SliderArrow from 'img/slider-arrow.inline.svg'
import Plaatjie from '@ubo/plaatjie'
import NavLink from 'components/shared/NavLink'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

const Section = styled.div`
  z-index: 4;
`

const Content = styled(ParseContent)`
  opacity: 0.3;
  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      color: ${({ theme }) => theme.color.primary};
      font-size: 70px;
      line-height: 70px;
    }
  }

  @media (max-width: 991.98px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      color: ${({ theme }) => theme.color.primary};
      font-size: ${({ theme }) => theme.font.size.giga};
      line-height: 59px;
    }
  }
`

const SwiperContainer = styled.div`
  max-width: calc(((100% - 1100px) / 2) + 1100px) !important;

  & .swiper-slide {
    height: auto;
    border-bottom-right-radius: 50px;
  }
`

const SliderNav = styled.div`
  & > div:hover {
    cursor: pointer;
    & svg > circle {
      fill: ${({ theme }) => theme.color.primary};
    }
  }

  & .custom-swiper-button-prev {
    transform: rotate(180deg);
  }

  & .swiper-button-disabled {
    opacity: 0.7;
    cursor: default !important;
  }
`

const HighlightedSlide = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 50px;

  @media (min-width: 992px) {
    margin-top: -1.5rem;
  }

  &:hover {
    opacity: 0.85;
  }
`

const HighlightedImage = styled(Plaatjie)`
  border-bottom-right-radius: 50px;
  filter: blur(12px);
`

const ContentWrapper = styled.div`
  color: ${({ theme }) => theme.color.light};
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    0.04deg,
    #a8c855 0.04%,
    rgba(0, 75, 150, 0) 126.97%,
    #ffffff 126.99%
  );
  border-bottom-right-radius: 50px;

  & * {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  @media (min-width: 992px) {
    & span {
      font-weight: ${({ theme }) => theme.font.weight.light};
      font-size: ${({ theme }) => theme.font.size.big};
    }
  }
`

const HighlightedContent = styled(ParseContent)`
  @media (min-width: 992px) {
    opacity: 0.3;

    & h3 {
      font-size: ${({ theme }) => theme.font.size.larger};
      line-height: ${({ theme }) => theme.font.size.huge};
    }
  }

  @media (min-width: 576px) {
    & h2 {
      font-size: 200px;
      line-height: 242px;
    }
  }

  @media (max-width: 575.98px) {
    & h2 {
      opacity: 0.3;
      font-size: 120px;
      line-height: 144px;
    }
  }
`

interface TeamProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Team
}

const TeamSlider: React.FC<TeamProps> = ({ fields }) => {
  const { allWpMember } =
    useStaticQuery<GatsbyTypes.teamSliderQueryQuery>(graphql`
      query teamSliderQuery {
        allWpMember(sort: { order: DESC, fields: menuOrder }) {
          edges {
            node {
              title
              recap {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 100, width: 600)
                    }
                  }
                }
                email {
                  title
                  url
                }
                linkedin
              }
            }
          }
        }
      }
    `)

  const members = allWpMember.edges

  members.sort((a, b) => {
    const arrA = a.node.title.split(' ')
    const arrB = b.node.title.split(' ')

    return arrA[arrA.length - 1].localeCompare(arrB[arrB.length - 1])
  })

  const firstSlides = members.slice(0, 2)
  const remainder = members.slice(2)

  return (
    <Section className="position-relative mb-5">
      <div className="container d-flex justify-content-between align-items-center">
        <Content content={fields.description || ''} />
        <div>
          <ButtonPrimary to="/about-us/our-team/">
            Bekijk het team
          </ButtonPrimary>
        </div>
      </div>
      <SwiperContainer className="position-relative container d-flex align-items-end me-0 ps-xl-0 pe-0">
        <SliderNav className="d-flex align-items-center">
          <div className="custom-swiper-button-prev">
            <SliderArrow />
          </div>
          <div className="custom-swiper-button-next ms-3">
            <SliderArrow />
          </div>
        </SliderNav>
        <Swiper
          className="ms-3 ms-sm-5"
          navigation={{
            prevEl: '.custom-swiper-button-prev',
            nextEl: '.custom-swiper-button-next',
          }}
          modules={[Navigation]}
          breakpoints={{
            0: {
              slidesPerView: 1.33,
              spaceBetween: 10,
            },
            576: {
              slidesPerView: 1.66,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 2.25,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 3.33,
              spaceBetween: 30,
            },
          }}
        >
          {firstSlides.map(({ node: member }, index) => (
            <SwiperSlide key={index} className="position-relative">
              <Member member={member} />
            </SwiperSlide>
          ))}
          <SwiperSlide>
            <NavLink to={fields.highlighted?.link?.url || '#'}>
              <HighlightedSlide className="position-relative">
                <HighlightedImage image={fields.highlighted?.image} alt="" />
                <ContentWrapper className="position-absolute p-3 p-lg-4 h-100 d-flex flex-column justify-content-end">
                  <HighlightedContent
                    content={fields.highlighted?.description || ''}
                  />
                  <span>{fields.highlighted?.link?.title}</span>
                </ContentWrapper>
              </HighlightedSlide>
            </NavLink>
          </SwiperSlide>
          {remainder.map(({ node: member }, index) => (
            <SwiperSlide key={index} className="position-relative">
              <Member member={member} />
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperContainer>
    </Section>
  )
}

export default TeamSlider
