import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

// Images
import Beam from 'img/bannerbeam.inline.svg'

// Interface
import { CtaProps } from 'components/flex/Cta/CtaShell'

const Section = styled.div`
  z-index: 4;
`

const SVGWrapper = styled.div`
  max-width: 1440px;
  bottom: 10px;
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${({ theme }) => theme.color.primary};
  }

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      position: relative;
      font-size: ${({ theme }) => theme.font.size.mega};
      line-height: 58px;

      &:before {
        position: absolute;
        content: '';
        width: 2000px;
        height: 2px;
        top: 26px;
        right: 110%;
        background-color: ${({ theme }) => theme.color.primary};
      }
    }
  }
`

const Title = styled.h2`
  opacity: 0.1;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.master};
    line-height: 83px;
  }

  @media (max-width: 991.98px) {
    font-size: ${({ theme }) => theme.font.size.huge};
    line-height: ${({ theme }) => theme.font.size.huge};
  }
`

const CTA = styled.div``

const IMGWrapper = styled.div`
  bottom: -9rem;
  left: 100px;
`

const Image = styled(Plaatjie)`
  @media (min-width: 1400px) {
    width: 345px;
    height: 524px;
  }

  @media (max-width: 1399.98px) {
    max-width: 315px;
    max-height: 474px;
  }

  @media (max-width: 991.98px) {
    max-width: 285px;
    max-height: 414px;
  }

  @media (max-width: 575.98px) {
    max-width: 175px;
    max-height: 264px;
  }
`

const CtaBasic: React.FC<CtaProps> = ({ fields }) => (
  <Section className="position-relative">
    <SVGWrapper className="position-absolute mx-auto">
      <Beam />
    </SVGWrapper>
    <div className="container pb-5">
      <div className="row justify-content-end pb-5">
        <div className="col-lg-11">
          <div className="row justify-content-between pb-5">
            <div className="col-4">
              <Content
                content={fields.description || ''}
                className="mt-sm-5 pt-sm-5"
              />
              <CTA className="mt-4 mt-sm-5 pt-sm-5">
                <ButtonPrimary to={fields.link?.url || '#'}>
                  {fields.link?.title}
                </ButtonPrimary>
              </CTA>
            </div>
            <div className="position-relative col-7">
              <div className="d-flex justify-content-end">
                <Title>{fields.title}</Title>
              </div>
              <IMGWrapper className="position-absolute ">
                <Image image={fields.image} alt="" className="h-100" />
              </IMGWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
)

export default CtaBasic
