import React from 'react'
import styled from 'styled-components'

// Images
import Leaf from 'img/leaf.svg'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    position: relative;

    &:before {
      position: absolute;
      top: 30px;
      content: '';
      height: 2px;
      width: 2000px;
      right: 104%;
      background-color: ${({ theme }) => theme.color.primary};
    }
  }

  & p {
    color: ${({ theme }) => theme.color.dark};
    line-height: ${({ theme }) => theme.font.size.larger};
  }

  & ul {
    color: ${({ theme }) => theme.color.dark};
    list-style: none;
    li {
      position: relative;
      margin-bottom: 0.75rem;

      &:before {
        position: absolute;
        left: -50px;
        content: url(${Leaf});
      }
    }
  }

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.mega};
      line-height: ${({ theme }) => theme.font.size.giga};
    }

    & ul {
      padding-left: 9rem;
    }
  }

  @media (max-width: 991.98px) {
    & ul {
      padding-left: 3rem;
    }
  }

  @media (max-width: 575.98px) {
    & p,
    & ul {
      margin-top: 2rem;
    }
  }
`

const TextColumns: React.FC<TextProps> = ({ fields }) => {
  return (
    <section className="mb-5 pb-lg-5">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="row">
              {fields.columns?.map((column, index) => (
                <div className="col-sm-6" key={index}>
                  <Content content={column?.description || ''} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextColumns
