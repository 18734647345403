import React from 'react'
import styled, { css } from 'styled-components'
import { PageProps } from 'gatsby'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'

const Portal = styled.div<{ highlighted?: boolean }>`
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 50px;

  ${(props) =>
    props.highlighted
      ? css`
          background-color: ${({ theme }) => theme.color.primary};
          color: ${({ theme }) => theme.color.light};
        `
      : css`
          color: ${({ theme }) => theme.color.dark};
        `}
`

const Content = styled(ParseContent)``

export interface DownloadsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Demo
  location?: PageProps['location']
  download: string
}

const DemoDownloads: React.FC<DownloadsProps> = ({ fields }) => {
  return (
    <section className="mb-5 pb-lg-5">
      <div className="container py-lg-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="row gx-sm-5">
              {fields.portals?.map((portal, index) => (
                <div className="col-6 col-sm-4 mb-4" key={index}>
                  <Portal
                    className="p-2 p-sm-4 h-100"
                    highlighted={index === 0}
                  >
                    <div className="d-flex flex-column justify-content-between h-100">
                      <div>
                        <h2 className="font-weight-bold mb-sm-4">
                          {portal?.title}
                        </h2>
                        <Content
                          content={portal?.description || ''}
                          className="mb-sm-5"
                        />
                      </div>
                      <div>
                        {portal?.links?.map((node) => (
                          <ButtonPrimary
                            chevron
                            target={node?.link?.target}
                            to={node?.link?.url || '#'}
                            className="mb-3 mx-2"
                          >
                            {node?.link?.title}
                          </ButtonPrimary>
                        ))}
                      </div>
                    </div>
                  </Portal>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DemoDownloads
