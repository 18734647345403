import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { HeroProps } from './HeroShell'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'
import NavLink from 'components/shared/NavLink'

const Section = styled.section`
  color: ${({ theme }) => theme.color.light};
  z-index: 5;

  @media (min-width: 992px) {
    margin-bottom: 6rem;
  }

  @media (max-width: 991.98px) {
    margin-bottom: 3rem;
  }
`

const BG = styled(Plaatjie)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 2000px;
`

const Content = styled(ParseContent)`
  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 70px;
      opacity: 0.3;
    }
  }
`

const Icon = styled(Plaatjie)`
  @media (max-width: 767.98px) {
    width: 50px;
    height: 50px;
  }
`

const Usp = styled.div`
  & h2 {
    font-size: ${({ theme }) => theme.font.size.bigger};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    color: ${({ theme }) => theme.color.light};
  }

  @media (min-width: 576px) {
    &:nth-child(odd) {
      margin-top: 8rem;
    }
  }

  @media (max-width: 575.98px) {
    margin-bottom: 1.5rem;
  }
`

const UspContent = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};

  & p {
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
`

const HeroImage: React.FC<HeroProps> = ({ fields }) => {
  const [currentHover, setCurrentHover] = useState(-1)

  return (
    <Section className="position-relative">
      <BG
        className="position-absolute w-100 h-100 mx-auto"
        image={fields.image}
        alt=""
      />
      <div className="container position-relative py-sm-5">
        <div className="d-flex align-items-center justify-content-between py-5">
          <Content content={fields.description || ''} />
          {/* <ButtonSecondary chevron to={fields.link?.url || '#'}>
            {fields.link?.title}
          </ButtonSecondary> */}
        </div>
        <div className="row my-sm-5 py-sm-5">
          <div className="col-lg-10">
            <div className="row">
              {fields.usps?.map((usp, index) => (
                <Usp className="col-sm-4" key={index}>
                  <motion.div
                    onHoverStart={() => setCurrentHover(index)}
                    onHoverEnd={() => setCurrentHover(-1)}
                  >
                    {fields.link?.url ? (
                      <NavLink to={usp?.link?.url || '#'} className="h-100">
                        <motion.div
                          animate={
                            currentHover === index ? { x: '30%' } : { x: '0%' }
                          }
                        >
                          <Icon image={usp?.icon} alt="" className="mb-3" />
                        </motion.div>
                        <h2>{usp?.link?.title}</h2>
                        <UspContent content={usp?.description || '#'} />
                      </NavLink>
                    ) : (
                      <div className="h-100">
                        <motion.div
                          animate={
                            currentHover === index ? { x: '30%' } : { x: '0%' }
                          }
                        >
                          <Icon image={usp?.icon} alt="" className="mb-3" />
                        </motion.div>
                        <h2>{usp?.link?.title}</h2>
                        <UspContent content={usp?.description || '#'} />
                      </div>
                    )}
                  </motion.div>
                </Usp>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default HeroImage
