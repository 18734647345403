import React from 'react'
import { graphql, PageProps } from 'gatsby'

// Components
import SEO, { SeoProps } from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler, {
  FlexContentFieldProps,
} from 'components/shared/FlexContentHandler'

export interface PageContextProps {
  exists: boolean
  title: string
}

export const PageContext = React.createContext({
  exists: false,
  title: '',
})

interface PageTemplateProps extends Omit<PageProps, 'data'> {
  data: {
    page: {
      title?: string
      path?: string
      seo: SeoProps
      flexContent: FlexContentFieldProps
    }
  }
  prefix?: string
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { title = '', flexContent, seo },
  },
  location,
  prefix = 'page_Flexcontent',
  pageContext,
}) => (
  <PageContext.Provider value={{ exists: true, title }}>
    <Layout>
      <SEO seo={seo} />
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
        pageContext={pageContext}
      />
    </Layout>
  </PageContext.Provider>
)

export const pageQuery = graphql`
  query pageById($pageId: String!) {
    page: wpPage(id: { eq: $pageId }) {
      ...generalPageFragment
    }
  }
`

export default PageTemplate
