import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Images
import Email from 'img/email.inline.svg'
import Linkedin from 'img/linkedin.inline.svg'

const MemberWrapper = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 50px;

  @media (min-width: 992px) {
    margin-top: -1.5rem;
  }
`

const Image = styled(Plaatjie)`
  border-bottom-right-radius: 50px;
`

const Details = styled.div`
  background: linear-gradient(0deg, #004b96 0%, rgba(0, 75, 150, 0) 96.08%);
  color: ${({ theme }) => theme.color.light};
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom-right-radius: 50px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  & span {
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  & h3 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  @media (min-width: 992px) {
    & h3 {
      font-size: ${({ theme }) => theme.font.size.large};
    }
    & span {
      font-size: ${({ theme }) => theme.font.size.big};
    }
  }

  @media (max-width: 575.98px) {
    & h3 {
      font-size: ${({ theme }) => theme.font.size.medium};
    }

    & span {
      font-size: ${({ theme }) => theme.font.size.small};
    }
  }

  & svg {
    margin-right: 10px;
    width: 20px;
    height: 18px;
  }
`

interface MemberProps {
  member?: any
}

const Member: React.FC<MemberProps> = ({ member }) => (
  <MemberWrapper className="position-relative">
    <Image image={member.recap.image} alt="" />
    <Details className="position-absolute px-2 px-sm-3 py-sm-4 py-3">
      <h3 className="mb-0">{member.title}</h3>
      <div className="d-flex align-items-center mt-2">
        {member.recap.email && (
          <a href={member.recap.email.url} className="text-hidden">
            <Email style={{ marginTop: 3 }} />
            {member.recap.email.title}
          </a>
        )}
        {member.recap.linkedin && (
          <a href={member.recap.linkedin} className="text-hidden">
            <Linkedin />
            {member.recap.email.title && (
              <span>{member.recap.email.title}</span>
            )}
          </a>
        )}
      </div>
    </Details>
  </MemberWrapper>
)

export default Member
