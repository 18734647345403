import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Elements
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'

// Images
import Chevron from 'img/chevron-secondary.inline.svg'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.primary};
  opacity: 0.3;

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 70px;
      line-height: 70px;
    }
  }

  @media (max-width: 991.98px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: 55px;
    }
  }
`

const ColumnContent = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 2rem;
    color: ${({ theme }) => theme.color.primary};
  }

  & h2,
  & h3 {
    font-size: ${({ theme }) => theme.font.size.large};
  }

  & p {
    color: ${({ theme }) => theme.color.dark};
  }

  & a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const ReadMore = styled.div`
  & > span {
    color: ${({ theme }) => theme.color.secondary};
  }
`

const TextLinks: React.FC<TextProps> = ({ fields }) => {
  const [currentHover, setCurrentHover] = useState(-1)

  return (
    <section className="mb-5 pb-lg-5">
      <div className="container py-5">
        <Content content={fields.description || ''} className="mb-4 mb-lg- 5" />
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="row">
              {fields.columns?.map((column, index) => (
                <div className="col-6" key={index}>
                  <NavLink to={column?.link?.url || '#'}>
                    <motion.div
                      onHoverStart={() => setCurrentHover(index)}
                      onHoverEnd={() => setCurrentHover(-1)}
                      animate={
                        currentHover === index ? { scale: 1.05 } : { scale: 1 }
                      }
                    >
                      <ColumnContent content={column?.description || ''} />
                      <ReadMore className="d-flex align-items-center w-100 mt-4">
                        <span>{column?.link?.title}</span>
                        <motion.div
                          animate={
                            currentHover === index ? { x: 20 } : { x: 10 }
                          }
                        >
                          <Chevron />
                        </motion.div>
                      </ReadMore>
                    </motion.div>
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextLinks
