import React from 'react'
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import Leaf from 'img/leaf.svg'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

const Section = styled.section<{ subtype?: string }>`
  @media (min-width: 1200px) {
    height: 673px;
  }

  @media (min-width: 992px) {
    ${(props) =>
      props.subtype === 'default'
        ? css`
            margin-bottom: 20rem;
          `
        : css`
            margin-bottom: 6rem;
          `}
  }

  @media (max-width: 991.98px) {
    margin-bottom: 3rem;
  }
`

const Wrapper = styled.div`
  max-width: 1320px;
`

const IMGWrapper = styled.div<{ subtype?: string }>`
  z-index: 2;
  @media (min-width: 1200px) {
    position: absolute;
    left: 0;
    padding-left: 0 !important;

    ${(props) =>
      props.subtype === 'list' &&
      css`
        top: 0;
      `}
  }

  @media (max-width: 1199.98px) {
    position: relative;
  }

  @media (max-width: 575.98px) {
    padding-left: 0 !important;
  }
`

const Image = styled(Plaatjie)`
  border-bottom-right-radius: 50px;
  @media (min-width: 1400px) {
    width: 537px;
    height: 673px;
  }

  @media (max-width: 575.98px) {
    max-width: 300px;
  }
`

const ContentWrapper = styled.div<{ subtype?: string }>`
  & h2 {
    color: ${({ theme }) => theme.color.primary};
  }

  ${(props) =>
    props.subtype === 'list' &&
    css`
      & h2:before {
        position: absolute;
        content: '';
        top: 20px;
        right: 95%;
        width: 100px;
        height: 2px;
        background-color: ${({ theme }) => theme.color.primary};
      }
    `}

  @media (min-width: 992px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: 44px;
    }
  }
`

const BG = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  border-bottom-right-radius: 50px;
`

const Content = styled(ParseContent)<{ subtype?: string }>`
  ${(props) =>
    props.subtype === 'default'
      ? css`
          & h1,
          & h2,
          & h3,
          & h4,
          & h5,
          & h6 {
            color: ${({ theme }) => theme.color.primary};
          }
          & p {
            color: ${({ theme }) => theme.color.dark};
            line-height: ${({ theme }) => theme.font.size.larger};
          }
        `
      : css`
          color: ${({ theme }) => theme.color.light};
        `}

  & ul {
    list-style: none;
    padding-left: 3rem;
    li {
      position: relative;
      font-weight: ${({ theme }) => theme.font.weight.bold};
      margin-bottom: 0.75rem;

      & a {
        color: ${({ theme }) => theme.color.light};
        text-decoration: none;

        &:hover {
          color: ${({ theme }) => theme.color.secondary};
        }
      }

      &:before {
        position: absolute;
        left: -50px;
        content: url(${Leaf});
      }
    }
  }
`

const Title = styled.h2`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.primary};
  opacity: 0.3;

  @media (min-width: 992px) {
    font-size: 70px;
    line-height: 70px;
  }
`

const TextImage: React.FC<TextProps> = ({ fields }) => {
  return (
    <Section subtype={fields.subtype}>
      <Wrapper className="position-relative mx-auto h-100">
        <div className="container h-100 py-5">
          {fields.subtype === 'default' && <Title>{fields.title}</Title>}

          <div className="row justify-content-center align-items-center h-100">
            <div className="col-lg-10">
              <div className="row justify-content-xl-end">
                <IMGWrapper
                  className={`col-${
                    fields.subtype === 'list' ? '5' : 'sm-5'
                  } mb-4`}
                  subtype={fields.subtype}
                >
                  <Image loading="eager" image={fields.image} alt="" />
                </IMGWrapper>
                {fields.subtype === 'default' && <Default fields={fields} />}
                {fields.subtype === 'list' && <List fields={fields} />}
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Section>
  )
}

const Default: React.FC<TextProps> = ({ fields }) => (
  <ContentWrapper className="col-sm-7 ps-xl-0" subtype={fields.subtype}>
    <div className="ps-md-4">
      <Content
        content={fields.description || ''}
        className="p-sm-3 pb-lg-5 ps-lg-5"
        subtype={fields.subtype}
      />
    </div>
  </ContentWrapper>
)

const List: React.FC<TextProps> = ({ fields }) => (
  <ContentWrapper className="col-7 ps-xl-0 me-xl-5" subtype={fields.subtype}>
    <h2 className="position-relative ps-5 ms-4 mb-lg-4 font-weight-bold">
      {fields.title}
    </h2>
    <BG className="px-md-4 me-md-5">
      <Content
        content={fields.description || ''}
        className="p-3 p-lg-5"
        subtype={fields.subtype}
      />
    </BG>
    {fields.link && (
      <div className="mt-3 m-lg-5 ps-lg-5">
        <ButtonPrimary
          to={fields.link?.url || '#'}
          chevron
          fontWeight="light"
          minWidth={180}
        >
          {fields.link?.title}
        </ButtonPrimary>
      </div>
    )}
  </ContentWrapper>
)

export default TextImage
