import React, { SetStateAction, useState } from 'react'
import styled, { css } from 'styled-components'
import { PageProps } from 'gatsby'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'
import Modal from 'components/elements/Misc/Modal'
import FormDownload from 'components/elements/Form/FormDownload'

const Download = styled.div<{ highlighted?: boolean }>`
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 50px;

  ${(props) =>
    props.highlighted &&
    css`
      background-color: ${({ theme }) => theme.color.primary};
      color: ${({ theme }) => theme.color.light};
    `}
`

const Icon = styled(Plaatjie)`
  width: 52px;
  height: 52px;
`

export interface DownloadsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Demo
  location?: PageProps['location']
  download: string
}

const DemoDownloads: React.FC<DownloadsProps> = ({ fields }) => {
  const [open, setOpen] = useState(false)
  const [currentDownload, setCurrentDownload] = useState<string>()

  return (
    <section className="mb-5 pb-lg-5">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="row gx-sm-5">
              {fields.downloads?.map((download, index) => (
                <div className="col-6 col-md-4 mb-4" key={index}>
                  {console.log(download?.file)}
                  <Download className="p-2 p-sm-4" highlighted={index === 0}>
                    <Icon
                      image={download?.icon}
                      alt=""
                      className="mb-3 mb-sm-5"
                    />
                    <h2 className="font-weight-bold mb-sm-4">
                      {download?.title}
                    </h2>
                    <ParseContent
                      content={download?.description || ''}
                      className="mb-sm-5"
                    />
                    <ButtonPrimary isCustom to="#" className="mb-4">
                      <button
                        onClick={() => {
                          setCurrentDownload(
                            download?.file?.localFile?.publicURL
                          )
                          setOpen(true)
                        }}
                      >
                        Download
                      </button>
                    </ButtonPrimary>
                  </Download>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {open && (
        <Modal isOpen={open} requestClose={() => setOpen(false)}>
          <FormDownload fields={fields} download={currentDownload || ''} />
        </Modal>
      )}
    </section>
  )
}

export default DemoDownloads
