import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper'

// Third party CSS
import 'swiper/css'
import 'swiper/css/pagination'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import SliderArrow from 'img/slider-arrow.inline.svg'

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};
`

const SwiperContainer = styled.div`
  & .swiper-slide {
    height: auto;
  }
`

const Image = styled(Plaatjie)`
  & img {
    object-fit: contain !important;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin: 1.5rem 0 2.5rem 0;
  }

  & p {
    line-height: ${({ theme }) => theme.font.size.larger};
  }
`

const SliderNav = styled.div`
  & > div:hover {
    cursor: pointer;
    & svg > circle {
      fill: ${({ theme }) => theme.color.secondary};
    }
  }

  & svg path {
    ${({ theme }) => theme.color.primary};
    stroke-width: 1.5;
    stroke: ${({ theme }) => theme.color.primary};
  }

  & .custom-swiper-button-prev {
    transform: rotate(180deg);
  }

  & .swiper-button-disabled {
    opacity: 0.7;
    cursor: default !important;
  }
`

const Bullets = styled.div`
  & .swiper-pagination-bullet {
    margin-right: 5.5px;
    width: 11px;
    height: 11px;
    background-color: ${({ theme }) => theme.color.light};
    opacity: 1;
  }

  & .swiper-pagination-bullet-active {
    background-color: ${({ theme }) => theme.color.secondary};
  }
`

// Interface
import { SystemsProps } from 'components/flex/Systems/SystemsShell'

const SystemsSlider: React.FC<SystemsProps> = ({ fields }) => (
  <Section className="mb-5">
    <div className="container py-5">
      <SwiperContainer className="position-relative">
        <Swiper
          className="ms-3 ms-sm-5"
          pagination={{
            el: '.custom-swiper-pagination',
          }}
          autoplay={{ delay: 8000 }}
          modules={[Navigation, Autoplay, Pagination]}
          navigation={{
            prevEl: '.custom-swiper-button-prev',
            nextEl: '.custom-swiper-button-next',
          }}
          slidesPerView={1}
        >
          {fields.systems?.map((system, index) => (
            <SwiperSlide key={index} className="position-relative w-100">
              <div className="row align-items-center">
                <div className="col-md-6 col-lg-7">
                  <Image image={system?.image} alt="" className="w-100 h-100" />
                  <Bullets className="custom-swiper-pagination d-flex justify-content-center" />
                </div>
                <div className="col-md-6 col-lg-5">
                  <SliderNav className="d-flex align-items-center">
                    <div className="custom-swiper-button-prev">
                      <SliderArrow />
                    </div>
                    <div className="custom-swiper-button-next ms-3">
                      <SliderArrow />
                    </div>
                  </SliderNav>
                  <Content content={system?.description || '#'} />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperContainer>
    </div>
  </Section>
)

export default SystemsSlider
