import Plaatjie from '@ubo/plaatjie'
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'
import React, { useState } from 'react'
import styled from 'styled-components'

// Interface
import { HeroProps } from './HeroShell'

const Section = styled.section`
  color: ${({ theme }) => theme.color.light};
  background-color: ${({ theme }) => theme.color.primary};

  & a {
    color: ${({ theme }) => theme.color.light};
  }

  @media (min-width: 992px) {
    margin-bottom: 6rem;
  }

  @media (max-width: 991.98px) {
    margin-bottom: 3rem;
  }
`

const HeroDefault: React.FC<HeroProps> = ({ fields }) => {
  const [currentHover, setCurrentHover] = useState(-1)

  return (
    <Section className="position-relative">
      <div className="container py-5">
        <div className="row justify-content-center pb-lg-5">
          <div className="col-lg-9">
            <div className="row">
              <ParseContent
                content={fields.description || ''}
                className="mb-5 pb-lg-4"
              />
              {fields.usps?.map((usp, index) => (
                <div className="col-6 col-sm-3 mb-4" key={index}>
                  <NavLink
                    to={usp?.link?.url || '#'}
                    className="d-flex flex-column align-items-center"
                  >
                    <Plaatjie image={usp?.icon} alt="" />
                    <span className="mt-3 text-center text-sm-start">
                      {usp?.link?.title}
                    </span>
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default HeroDefault
