import React from 'react'
import ReactModal from 'react-modal'
import Layout from 'components/layout/Layout'
import useMedia from 'use-media'

interface ModalProps {
  isOpen: boolean
  requestClose: (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => void
  children: any
}

const Modal: React.FC<ModalProps> = ({ children, isOpen, requestClose }) => {
  const isWide = useMedia({ minWidth: 992 })

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(1, 35, 69, 0.7)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderBottomRightRadius: '50px',
      padding: '3rem',
      backgroundColor: '#FFF',
      height: !isWide ? '70%' : '',
    },
  }

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={requestClose}
      ariaHideApp={false}
    >
      {children}
    </ReactModal>
  )
}

export default Modal
