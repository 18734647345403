import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${({ theme }) => theme.color.primary};
    opacity: 0.3;
  }

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 70px;
      line-height: 70px;
    }
  }

  @media (max-width: 991.98px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.mega};
      line-height: 61px;
    }
  }
`

const System = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 50px;
`

const SVGWrapper = styled.div`
  top: 20px;
  left: 20px;
  z-index: 2;
`

const Icon = styled(Plaatjie)`
  width: 52px;
  height: 52px;
`

// Interface
import { SystemsProps } from 'components/flex/Systems/SystemsShell'

const SystemsDefault: React.FC<SystemsProps> = ({ fields }) => (
  <section>
    <div className="container py-5">
      <Content content={fields.description || ''} />
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <div className="row justify-content-around">
            {fields.systems?.map((system, index) => (
              <div className="col-sm-6 col-lg-5 mb-5" key={index}>
                <System className="position-relative">
                  <SVGWrapper className="position-absolute">
                    <Icon image={system?.icon} alt="" />
                  </SVGWrapper>
                  <Plaatjie image={system?.image} alt="" />
                  <div className="px-4 px-md-5 py-3 py-md-4">
                    <ParseContent content={system?.description || ''} />
                    <ButtonPrimary
                      to={system?.link?.url || '#'}
                      className="mt-4"
                      chevron
                    >
                      {system?.link?.title}
                    </ButtonPrimary>
                  </div>
                </System>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default SystemsDefault
