import React from 'react'
import styled from 'styled-components'

// CSS
import { FormCSS } from 'styles/form'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

// Interface
import { FormProps } from 'components/flex/Form/FormShell'

const StyledFormDuuf = styled(FormDuuf)`
  ${FormCSS}
`

const FormContact: React.FC<FormProps> = ({ fields }) => (
  <>
    <StyledFormDuuf
      id={1}
      generate
      privacyUrl="/privacy-policy/"
      privacyUrlPlacement="privacy policy"
    />
  </>
)

export default FormContact
