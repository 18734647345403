import React from 'react'
import { motion } from 'framer-motion'

interface WaveProps {}

const Wave: React.FC<WaveProps> = () => (
  <svg
    width="773"
    height="230"
    viewBox="0 0 773 230"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_148_127)">
      <path
        d="M771.384 50.0592C642.021 5.4211 521.187 4.05921 386.465 49.5296C251.744 95 174.006 150.911 2 212.724"
        fill="white"
      />
      <path
        d="M770.742 33.3653C598.736 95.0265 520.772 151.316 386.277 196.711C251.782 242.105 130.721 240.743 1.3584 196.181"
        fill="white"
      />
      <path
        d="M622.638 78.1972L386 191.38L1152 196L961.46 108.551L844.678 61.2582L763.566 32L622.638 78.1972Z"
        fill="white"
        stroke="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_148_127">
        <rect width="2304" height="230" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Wave
