import React from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import Beam from 'img/bannerbeam.inline.svg'
import Wave from 'components/elements/Vectors/Wave'
import Chevron from 'img/chevron-round.inline.svg'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

const Section = styled.section``

const BannerWrapper = styled.div`
  @media (min-width: 1400px) {
    min-height: 907px;
  }
`

const ContentWrapper = styled.div`
  z-index: 2;
  top: 100px;

  @media (min-width: 768px) {
    padding-top: 12.5%;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 22px;
      right: 101%;
      width: 2000px;
      height: 2px;
      background-color: ${({ theme }) => theme.color.light};
    }
  }

  & p {
    font-size: ${({ theme }) => theme.font.size.bigger} !important;
    line-height: ${({ theme }) => theme.font.size.bigger} !important;
  }

  & * {
    text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.1);
  }

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: ${({ theme }) => theme.font.size.mega};
    }
  }

  @media (max-width: 575.98px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.medium};
      line-height: ${({ theme }) => theme.font.size.big};
    }

    & p {
      margin-bottom: 0;
      font-size: ${({ theme }) => theme.font.size.small} !important;
      line-height: ${({ theme }) => theme.font.size.medium};
    }
  }
`

const Gradient = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, #001224 25.92%, rgba(1, 35, 69, 0) 80%);
  mix-blend-mode: multiply;
  opacity: 0.6;
  z-index: 2;
`

const VideoWrapper = styled.div`
  padding-top: 56.25%;
  z-index: 2;
`

const Iframe = styled.iframe`
  top: 0px;
  left: 0px;
`

const SVGWrapper = styled.div`
  max-width: 1440px;
  margin-top: -7rem;
  z-index: 5;
`

const WaveWrapper = styled.div`
  bottom: 40px;
  right: -40px;
  z-index: 4;
`

const ChevronWrapper = styled(motion.div)`
  top: -15%;
  right: 25%;

  @media (max-height: 890px) {
    top: -55%;
  }

  @media (max-height: 800px) {
    top: -100%;
  }

  @media (max-height: 720px) {
    top: -150%;
  }
`

const BannerLanding: React.FC<BannerProps> = ({ fields }) => (
  <Section className="mb-5">
    <BannerWrapper className="position-relative">
      <VideoWrapper className="position-relative overflow-hidden">
        <Iframe
          title="Gooiland Elektro header"
          src={`${fields.vimeo}&muted=1&loop=1&autoplay=1&background=1`}
          width="100%"
          height="100%"
          style={{ border: 0 }}
          loading="lazy"
          allow="autoplay"
          className="position-absolute"
        />
      </VideoWrapper>
      <Gradient className="position-absolute" />
      <div className="position-relative d-none d-xl-block">
        <SVGWrapper className="position-relative ms-auto">
          <ChevronWrapper
            className="position-absolute"
            animate={{ y: [-5, 5] }}
            transition={{ yoyo: Infinity }}
          >
            <a href={`#${fields.goto}`}>
              <Chevron />
            </a>
          </ChevronWrapper>
          <Beam />
        </SVGWrapper>
        <WaveWrapper className="position-absolute">
          <Wave />
        </WaveWrapper>
      </div>

      <ContentWrapper className="position-absolute w-100">
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-xl-5">
              <Content content={fields.description || ''} />
            </div>
          </div>
        </div>
      </ContentWrapper>
    </BannerWrapper>
  </Section>
)

export default BannerLanding
