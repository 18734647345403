import React from 'react'
import styled from 'styled-components'

// Interface
import { CtaProps } from 'components/flex/Cta/CtaShell'
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

const Section = styled.div`
  z-index: 4;
`

const Title = styled.h2`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.primary};
  opacity: 0.3;

  @media (min-width: 992px) {
    font-size: 70px;
    line-height: 70px;
  }

  @media (max-width: 991.98px) {
    font-size: ${({ theme }) => theme.font.size.huge};
    line-height: ${({ theme }) => theme.font.size.huge};
  }
`

const Cta = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};
  border-bottom-left-radius: 50px;

  &:after {
    position: absolute;
    content: '';
    width: 2000px;
    top: 0;
    left: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.color.primary};
  }
`

const Content = styled(ParseContent)`
  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.larger};
    }

    & p {
      line-height: ${({ theme }) => theme.font.size.larger};
    }
  }
`

const Image = styled(Plaatjie)`
  z-index: 2;

  @media (min-width: 992px) {
    position: absolute;
    right: -50px;
    width: 250px;
    height: 300px;
    bottom: 0;
  }

  @media (max-width: 991.98px) {
    position: relative;
    width: 200px;
    height: 250px;
    bottom: -48px;
  }

  @media (max-width: 767.98px) {
    bottom: -24px;
    right: 45px;
  }
`

const CtaDefault: React.FC<CtaProps> = ({ fields }) => (
  <Section className="position-relative mb-5 pb-lg-5">
    <div className="container">
      <Title>{fields.title}</Title>
      <div className="row justify-content-end">
        <div className="col-lg-11">
          <div className="row">
            <div className="col-sm-6">
              <ParseContent
                content={fields.description || '#'}
                className="mt-4 pe-lg-5"
              />
            </div>
            <div className="col-sm-6">
              <Cta className="position-relative p-4 p-md-5 mt-4 mt-lg-0">
                <div className="row align-items-end align-items-lg-center">
                  <div className="col-8">
                    <Content content={fields.ctadescription || '#'} />
                    <ButtonPrimary
                      to={fields.link?.url || '#'}
                      className="mt-4"
                      chevron
                    >
                      {fields.link?.title}
                    </ButtonPrimary>
                  </div>
                  <div className="col-4">
                    <Image image={fields.image} alt="" />
                  </div>
                </div>
              </Cta>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
)

export default CtaDefault
