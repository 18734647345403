import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import Member from 'components/elements/Misc/Member'

const Section = styled.section``

const TeamWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 6rem;
  }

  & h2 {
    color: ${({ theme }) => theme.color.primary};
    opacity: 0.3;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  @media (min-width: 992px) {
    & h2 {
      font-size: 70px;
      line-height: 70px;
    }
  }
`

interface TeamProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Team
}

const TeamDefault: React.FC<TeamProps> = ({ fields }) => {
  const { allWpMember, allWpTeam } =
    useStaticQuery<GatsbyTypes.teamDefaultQueryQuery>(graphql`
      query teamDefaultQuery {
        allWpMember(sort: { order: ASC, fields: menuOrder }) {
          edges {
            node {
              menuOrder
              title
              recap {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 100, width: 600)
                    }
                  }
                }
                email {
                  title
                  url
                }
                linkedin
              }
              teams {
                nodes {
                  databaseId
                  name
                }
              }
            }
          }
        }
        allWpTeam {
          edges {
            node {
              databaseId
              name
            }
          }
        }
      }
    `)

  const members = allWpMember.edges
  const teams = allWpTeam.edges

  return (
    <Section className="mb-5 pb-lg-5">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {teams.map(({ node: team }, teamIndex) => {
              return (
                <TeamWrapper key={teamIndex}>
                  <h2 className="mb-5">{team.name}</h2>
                  <div className="row">
                    {members
                      .filter(({ node: member }) =>
                        member.teams?.nodes?.some(
                          (node) => node?.databaseId === team.databaseId
                        )
                      )
                      .sort(
                        (a, b) =>
                          (a.node.menuOrder || 0) - (b.node.menuOrder || 0)
                      )
                      .map(({ node: member }, memberIndex) => {
                        return (
                          <div
                            className="col-6 col-sm-4 col-md-3 mb-5"
                            key={`${teamIndex}-${memberIndex}`}
                          >
                            <Member member={member} />
                          </div>
                        )
                      })}
                  </div>
                </TeamWrapper>
              )
            })}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default TeamDefault
