import React from 'react'
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Breadcrumbs from 'components/elements/Misc/Breadcrumbs'

// Images
import Beam from 'img/bannerbeam.inline.svg'
import Wave from 'components/elements/Vectors/Wave'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

// Hooks
import usePage from 'hooks/usePage'

const Section = styled.section``

const BannerWrapper = styled.div`
  @media (min-width: 992px) {
    min-height: 501px;
  }

  @media (max-width: 991.98px) {
    min-height: 400px;
    padding-bottom: 42.5%;
  }
`

const ContentWrapper = styled.div`
  color: ${({ theme }) => theme.color.light};
  z-index: 6;
  top: 100px;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 22px;
      right: 104%;
      width: 2000px;
      height: 2px;
      background-color: ${({ theme }) => theme.color.light};
    }
  }

  @media (min-width: 992px) {
    position: absolute;
  }

  @media (min-width: 768px) {
    padding-top: 6rem;
  }

  @media (max-width: 991.98px) {
    position: relative;
  }
`

const Content = styled(ParseContent)`
  & * {
    text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.1);
  }

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: ${({ theme }) => theme.font.size.ultra};
    }

    & p {
      line-height: ${({ theme }) => theme.font.size.larger};
    }
  }

  @media (max-width: 575.98px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.medium};
      line-height: ${({ theme }) => theme.font.size.big};
    }

    & p {
      margin-bottom: 0;
      font-size: ${({ theme }) => theme.font.size.medium} !important;
      line-height: ${({ theme }) => theme.font.size.big};
    }
  }
`

const Gradient = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    89.86deg,
    #004b96 20.08%,
    rgba(0, 75, 150, 0) 54.51%
  );
  mix-blend-mode: multiply;
  opacity: 0.6;
  z-index: 2;
`

const SVGWrapper = styled.div`
  max-width: 1440px;
  margin-top: -6rem;
  z-index: 5;
`

const WaveWrapper = styled.div`
  bottom: 40px;
  right: -40px;
  z-index: 4;
`

const Overlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #012445;
  mix-blend-mode: multiply;
  opacity: 0.7;
`

const Image = styled(Plaatjie)`
  z-index: 7;
  max-width: 450px;
  max-height: 450px;
`

const BannerDefault: React.FC<BannerProps> = ({ fields, pageContext }) => {
  const page = usePage()
  return (
    <Section>
      <BannerWrapper className="position-relative">
        <Plaatjie
          image={fields.image}
          alt=""
          className="position-absolute w-100 h-100"
        />
        <Gradient className="position-absolute" />
        <Overlay className="position-absolute" />

        <ContentWrapper className="position-relative w-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="row">
                  <div className="col-5 col-md-6">
                    <h1 className="font-weight-bold">{page.title}</h1>
                    <div className="my-4 my-lg-5">
                      <Breadcrumbs pageContext={pageContext} />
                    </div>
                    <div className="row">
                      <div className="col-sm-10">
                        <Content content={fields.description || ''} />
                      </div>
                    </div>
                  </div>
                  {fields.subimage && (
                    <div className="col-7 col-md-6">
                      <div className="d-flex justify-content-end">
                        <Image
                          image={fields.subimage}
                          alt=""
                          className="position-relative"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ContentWrapper>
      </BannerWrapper>
      <div className="position-relative d-none d-xl-block">
        <SVGWrapper className="position-relative mx-auto">
          <Beam />
        </SVGWrapper>
        <WaveWrapper className="position-absolute">
          <Wave />
        </WaveWrapper>
      </div>
    </Section>
  )
}

export default BannerDefault
